#customModal {
  #modalBody {
    background-color: #ffffff;
    background-image: url("../../../assets/img/proyecto-image.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
    padding: 20px;
  }
  #modalLogo {
    max-width: 200px;
    filter: drop-shadow(3px 3px 3px rgb(37, 36, 36));
  }
  #modalTitle {
    font-size: 28px;
    text-align: center;
    color: #ffffff;
    filter: drop-shadow(3px 3px 3px rgb(37, 36, 36));
    margin-top: 20px;
    margin-bottom: 20px;
  }
  #modalMiddleContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    p {
      text-align: center;
    }
  }
  #modalBottomContent {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: -30px;
    flex-wrap: wrap;
    p {
      font-size: 16px;
    }
  }
  #modalFooter {
    display: flex;
    justify-content: center;
  }
}

#customModal p,
#customModal li {
  font-size: 22px;
  color: #ffffff;
  filter: drop-shadow(3px 3px 3px rgb(37, 36, 36));
  font-weight: 700;
}

@media (max-width: 400px) {
  #slogan {
    display: none;
  }
}
